.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.products-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.row{
    padding: 0px 15px 0px 15px;
}


.titles{
    font-size: 40px;
    font-weight: bold;
}

.imageContainerEmpaques{
    border-radius: 20px;
    background: #EDF0F4;
    width: 65%;
    height: 55%;
}


.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 20px;
    background: #EDF0F4;
    width: 440px;
    height: 520px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 440px;
    height: 520px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 470px;
    height: 380px;
    justify-content: center; 
    align-items: center;
    display: flex;
    padding-bottom: 20px;
}

.logoHalal{
    width: 150px;
    height: 150px;
}

.logoKmd{
    width: 180px;
    height: 180px;
}

.logoSqf{
    width: 210px;
    height: 200px;
}