.contact-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.titles{
    font-size: 42px;
    font-weight: bold;
}

.imageContainer{
    border-radius: 16px;
    width: 100%;
    height: 100%;
}

.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 20px;
    width: 440px;
    height: 520px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 440px;
    height: 520px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 470px;
    height: 380px;
    justify-content: center; 
    align-items: center;
    display: flex;
    padding-bottom: 20px;
}

.logoHalal{
    width: 150px;
    height: 150px;
}

.logoKmd{
    width: 180px;
    height: 180px;
    padding-bottom: 22px;
}

.logoSqf{
    width: 210px;
    height: 200px;
    padding-bottom: 50px;
}

.title-contact{
    width: 196px;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 22px;
    line-height: 1.2;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.container-data-contact-ct{
    text-align: center;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
}

.direccion-contact-ct{
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
    margin-bottom: 2px;
    color: black;
}

.row-contact-content-ct{
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
}

.logo-contact-ct{
    font-size: 18px;
    color: black;
}