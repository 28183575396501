*{
    box-sizing: border-box;
}


.logo{
    padding-left: 10px;
    height: 105px;
    width: 150px;
}

.logo-footer{
    height: 110px;
    width: 160px;
}

body{
    background-color:rgba(255, 255, 0, 0) !important;
}

.nav{
    background-color: #A20402;;
    color: white;
    font-weight: 700;
    display: flex;
    font-family: "Open Sans";
    justify-content: space-between;
    align-content: stretch;
    gap: 1rem;
    padding: 0 1em;
    font-size: 25px;
    width: 100%;
}

.nav ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a{
    color:inherit;
    text-decoration: solid;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active{
    background-color: #555;
}

.nav .link:hover{
    color: #379762;
}

ul.footer-list li{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-footer{
    color: #FCFBF9;
    background-color: #FCFBF9;
    width: 100%;
}

.title-footer{
    width: 196px;
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 22px;
    line-height: 1.2;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.container-data-contact{
    text-align: center;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
}

.direccion-contact{
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
    margin-bottom: 2px;
}

.row-contact-content{
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
}

.logo-contact{
    color:#ffffff;
    font-size: 18px;
}

.img2 { background-image: url("./tractor_new.jpg"); }

.footer{
    background-color: #A20402;
}

.bg-image {
    /* Full height */
    height: 100%; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size:  100% auto;
  }






