html {
  height:100%;
  
}

.{
  font-family: "Open Sans" !important; 
}


.bg {
  -webkit-animation:slide 5s ease-in-out infinite alternate;
          animation:slide 5s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #379762 50%, #D0322B 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  -webkit-animation-duration:6s;
          animation-duration:6s;
}

.bg3 {
  -webkit-animation-duration:5s;
          animation-duration:5s;
}


@-webkit-keyframes slide {
  0% {
    -webkit-transform:translateX(-25%);
            transform:translateX(-25%);
  }
  100% {
    -webkit-transform:translateX(25%);
            transform:translateX(25%);
  }
}


@keyframes slide {
  0% {
    -webkit-transform:translateX(-25%);
            transform:translateX(-25%);
  }
  100% {
    -webkit-transform:translateX(25%);
            transform:translateX(25%);
  }
}

.App {
  text-align: center;
  font-family: "Open Sans" !important; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.home-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.firstElement{
    padding-left: 35px;
}

.thirdElement{
    padding-left: 35px;
}


.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.titles{
    font-size: 42px;
    font-weight: bold;
}

.imageContainer{
    border-radius: 16px;
    background: #EDF0F4;
    width: 100%;
    height: 100%;
}

.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 15px;
    background: #EDF0F4;
    width: 350px !important;
    height: 420px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 350px !important;
    height: 420px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 375px;
    height: 275px;
}

.logoHalal{
    width: 150px;
    height: 150px;
    justify-self: center !important;
}

.logoKmd{
    width: 165px;
    height: 180px;
    justify-self: center !important;
}

.logoSqf{
    padding: 10px 0px 0px 0px;
    width: 215px !important;
    height: 195px !important;
    justify-self: center !important;
}
.home-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.firstElement{
    padding-left: 35px;
}

.thirdElement{
    padding-left: 35px;
}


.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.titles{
    font-size: 42px;
    font-weight: bold;
}

.imageContainer{
    border-radius: 16px;
    background: #EDF0F4;
    width: 100%;
    height: 100%;
}

.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 15px;
    background: #EDF0F4;
    width: 350px !important;
    height: 420px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 350px !important;
    height: 420px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 375px;
    height: 275px;
}

.logoHalal{
    width: 150px;
    height: 150px;
    justify-self: center !important;
}

.logoKmd{
    width: 165px;
    height: 180px;
    justify-self: center !important;
}

.logoSqf{
    padding: 10px 0px 0px 0px;
    width: 215px !important;
    height: 195px !important;
    justify-self: center !important;
}
.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.products-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.row{
    padding: 0px 15px 0px 15px;
}


.titles{
    font-size: 40px;
    font-weight: bold;
}

.imageContainerEmpaques{
    border-radius: 20px;
    background: #EDF0F4;
    width: 65%;
    height: 55%;
}


.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 20px;
    background: #EDF0F4;
    width: 440px;
    height: 520px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 440px;
    height: 520px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 470px;
    height: 380px;
    justify-content: center; 
    align-items: center;
    display: flex;
    padding-bottom: 20px;
}

.logoHalal{
    width: 150px;
    height: 150px;
}

.logoKmd{
    width: 180px;
    height: 180px;
}

.logoSqf{
    width: 210px;
    height: 200px;
}
.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.products-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.row{
    padding: 0px 15px 0px 15px;
}


.titles{
    font-size: 40px;
    font-weight: bold;
}

.imageContainerEmpaques{
    border-radius: 20px;
    background: #EDF0F4;
    width: 65%;
    height: 55%;
}


.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 20px;
    background: #EDF0F4;
    width: 440px;
    height: 520px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 440px;
    height: 520px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 470px;
    height: 380px;
    justify-content: center; 
    align-items: center;
    display: flex;
    padding-bottom: 20px;
}

.logoHalal{
    width: 150px;
    height: 150px;
}

.logoKmd{
    width: 180px;
    height: 180px;
}

.logoSqf{
    width: 210px;
    height: 200px;
}
.logistic-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}
.logistic-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}
.contact-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.titles{
    font-size: 42px;
    font-weight: bold;
}

.imageContainer{
    border-radius: 16px;
    width: 100%;
    height: 100%;
}

.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 20px;
    width: 440px;
    height: 520px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 440px;
    height: 520px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 470px;
    height: 380px;
    justify-content: center; 
    align-items: center;
    display: flex;
    padding-bottom: 20px;
}

.logoHalal{
    width: 150px;
    height: 150px;
}

.logoKmd{
    width: 180px;
    height: 180px;
    padding-bottom: 22px;
}

.logoSqf{
    width: 210px;
    height: 200px;
    padding-bottom: 50px;
}

.title-contact{
    width: 196px;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 22px;
    line-height: 1.2;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.container-data-contact-ct{
    text-align: center;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
}

.direccion-contact-ct{
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
    margin-bottom: 2px;
    color: black;
}

.row-contact-content-ct{
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
}

.logo-contact-ct{
    font-size: 18px;
    color: black;
}
.contact-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.titles{
    font-size: 42px;
    font-weight: bold;
}

.imageContainer{
    border-radius: 16px;
    width: 100%;
    height: 100%;
}

.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 20px;
    width: 440px;
    height: 520px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 440px;
    height: 520px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 470px;
    height: 380px;
    justify-content: center; 
    align-items: center;
    display: flex;
    padding-bottom: 20px;
}

.logoHalal{
    width: 150px;
    height: 150px;
}

.logoKmd{
    width: 180px;
    height: 180px;
    padding-bottom: 22px;
}

.logoSqf{
    width: 210px;
    height: 200px;
    padding-bottom: 50px;
}

.title-contact{
    width: 196px;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 22px;
    line-height: 1.2;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.container-data-contact-ct{
    text-align: center;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
}

.direccion-contact-ct{
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
    margin-bottom: 2px;
    color: black;
}

.row-contact-content-ct{
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
}

.logo-contact-ct{
    font-size: 18px;
    color: black;
}
*{
    box-sizing: border-box;
}


.logo{
    padding-left: 10px;
    height: 105px;
    width: 150px;
}

.logo-footer{
    height: 110px;
    width: 160px;
}

body{
    background-color:rgba(255, 255, 0, 0) !important;
}

.nav{
    background-color: #A20402;;
    color: white;
    font-weight: 700;
    display: flex;
    font-family: "Open Sans";
    justify-content: space-between;
    align-content: stretch;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 1em;
    font-size: 25px;
    width: 100%;
}

.nav ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.nav a{
    color:inherit;
    -webkit-text-decoration: solid;
            text-decoration: solid;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active{
    background-color: #555;
}

.nav .link:hover{
    color: #379762;
}

ul.footer-list li{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-footer{
    color: #FCFBF9;
    background-color: #FCFBF9;
    width: 100%;
}

.title-footer{
    width: 196px;
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 22px;
    line-height: 1.2;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.container-data-contact{
    text-align: center;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
}

.direccion-contact{
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
    margin-bottom: 2px;
}

.row-contact-content{
    color: #ffffff;
    font-family: "Open Sans";
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    font-size: 3erm;
    padding-left: 3px;
}

.logo-contact{
    color:#ffffff;
    font-size: 18px;
}

.img2 { background-image: url(/static/media/tractor_new.740b603b.jpg); }

.footer{
    background-color: #A20402;
}

.bg-image {
    /* Full height */
    height: 100%; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size:  100% auto;
  }







