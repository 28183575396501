.home-container{
    background-color:rgb(255, 255, 255, .85) !important;
    padding-left: 30px;
}

.firstElement{
    padding-left: 35px;
}

.thirdElement{
    padding-left: 35px;
}


.texts{
    font-size: 22px;
    font-weight: 200;
    text-align: justify;
}

.titles{
    font-size: 42px;
    font-weight: bold;
}

.imageContainer{
    border-radius: 16px;
    background: #EDF0F4;
    width: 100%;
    height: 100%;
}

.mainImage{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.image{
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.imageContainerx{
    border-radius: 15px;
    background: #EDF0F4;
    width: 350px !important;
    height: 420px;
}
 
.mainImagex{
    border-radius: 20px;
    padding: 10px;
    width: 350px !important;
    height: 420px;
}

.titleImages{
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 10px;
}

.logoImage{
    width: 375px;
    height: 275px;
}

.logoHalal{
    width: 150px;
    height: 150px;
    justify-self: center !important;
}

.logoKmd{
    width: 165px;
    height: 180px;
    justify-self: center !important;
}

.logoSqf{
    padding: 10px 0px 0px 0px;
    width: 215px !important;
    height: 195px !important;
    justify-self: center !important;
}